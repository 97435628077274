// This polyfill file gets bundled with Next's polyfills in a `nomodule` script tag.
// It will only get loaded by browsers that don't support the `nomodule` attribute: https://caniuse.com/#feat=mdn-html_elements_script_nomodule

// Next.js includes a number of polyfills only for older browsers like IE11
// Make sure you don't duplicate these in this file
// https://github.com/zeit/next.js/blob/canary/packages/next-polyfill-nomodule/src/index.js
import "intersection-observer";

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
